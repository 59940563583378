import * as React from "react";
import { usePairing } from "../hooks/pairing/usePairing";
import { useBadgeNumber } from "../hooks/useBadgeNumber";
import { LcgButton } from "./lcgComponents/LcgButton";
import { LcgContainer } from "./lcgComponents/LcgContainer";
import { LcgFooter } from "./lcgComponents/LcgFooter";
import { LcgHeader } from "./lcgComponents/LcgHeader";
import { LcgInput } from "./lcgComponents/LcgInput";
import { LcgPopup } from "./lcgComponents/LcgPopup";

export const XGilHome: React.FunctionComponent<{}> = () => {
  const [
    badgeNumber,
    setBadgeNumber,
    getPairWithBadgePayload,
  ] = useBadgeNumber();

  const [onConnect, popupDisplayed, popupContent, pending] = usePairing(
    "xgil",
    "Pensez à vérifier votre connexion ainsi que la bonne saisie de votre numéro de badge",
    "Nous vous avons bien affilié à votre badge entreprise mais le transfert de votre cagnotte n'a pas pu aboutir",
    getPairWithBadgePayload
  );

  return (
    <>
      <LcgContainer>
        <LcgHeader>AFFILIER MA CAGNOTTE A MON BADGE ENTERPRISE</LcgHeader>
        <LcgInput onValueChange={setBadgeNumber}>N° de badge</LcgInput>
      </LcgContainer>
      <LcgFooter>
        <LcgButton
          disabled={badgeNumber === "" || badgeNumber === null || pending}
          onClick={onConnect}
        >
          S'affilier
        </LcgButton>
      </LcgFooter>
      {popupDisplayed && popupContent && (
        <LcgPopup {...popupContent}></LcgPopup>
      )}
    </>
  );
};
