import { Linking } from "react-native";
import { greengoApi } from "../../api/greengoApi";
import { PairRequest, TransferCreditRequest } from "../../models/affiliation";
import { assert, hasOwnProperty } from "../../typings/utils";
import { PairError, TransferError, UnhandledPairError } from "./error";
import { PopupInfo } from "./popup";

export const pairUser = async (
  mobileAppToken: string | null,
  service: string,
  pairPayload: PairRequest
) => {
  try {
    const pairingResult = await greengoApi.pair(
      mobileAppToken!,
      service,
      pairPayload
    );

    if (!pairingResult.success) {
      if (
        pairingResult.errorLabel === "user_not_found" ||
        pairingResult.errorLabel === "several_accounts_match" ||
        pairingResult.errorLabel === "user_already_paired"
      ) {
        throw new PairError("backend refuses to pair user for a good reason");
      } else {
        throw new UnhandledPairError(
          "backend refuses to pair user for a reason we ignore"
        );
      }
    }
  } catch (e) {
    throw new PairError("internet connexion was probably not succesful");
  }
};

export const transferCredit = async (
  mobileAppToken: string | null,
  service: string,
  transferPayload: TransferCreditRequest,
  setPopupContent: (content: PopupInfo) => void,
  togglePopup: () => void,
  onValidateLink: string
) => {
  try {
    const transferResult = await greengoApi.transferCredit(
      mobileAppToken!,
      service,
      transferPayload
    );

    if (hasOwnProperty(transferResult, "creditError")) {
      throw new TransferError(
        "a creditError happens when transfering cagnotte amount"
      );
    }

    assert(!hasOwnProperty(transferResult, "creditError"), "");

    setPopupContent({
      title: "Merci !",
      details: transferResult.recreditMessage,
      button: "Revenir sur l'application",
      onValidate: () => {
        Linking.openURL(onValidateLink);
      },
    });
    togglePopup();
  } catch {
    throw new TransferError(
      "an unhandled error happens when transfering cagnotte amount"
    );
  }
};
