import env from "../environment/env";
import { PairRequest, TransferCreditRequest } from "../models/affiliation";
import { apiFetch, checkStatus } from "./utils";
const greengoUrl = env.GREENGO_API;

export interface SuccessPairResult {
  success: true;
}

export interface ErrorPairResult {
  success: false;
  errorLabel: PairError;
  errorDescription: PairError;
  errorCode: number;
}

export interface ServicePayload {
  paired: boolean;
  creditExecuted: boolean;
  recreditMessage: string;
  service: string;
  pairingMessage?: string;
  pairingUrl?: string;
}

export interface ServiceErrorPayload {
  service: string;
  creditError: string;
}

export type PairError = "user_not_found" | "several_accounts_match";

export interface GreengoApi {
  pair: (
    token: string,
    service: string,
    params: PairRequest
  ) => Promise<SuccessPairResult | ErrorPairResult>;
  transferCredit: (
    token: string,
    service: string,
    params: TransferCreditRequest
  ) => Promise<ServicePayload | ServiceErrorPayload>;
}

export const greengoApi: GreengoApi = {
  pair: async (token, service, params) => {
    try {
      const res = await apiFetch(`${greengoUrl}/interrop/pair/${service}`, {
        method: "POST",
        body: JSON.stringify({ ...params }),
        headers: { "x-auth": token },
      });

      checkStatus(res);

      let json = await res.json();
      return json;
    } catch (e) {
      console.error(e);
    }
  },
  transferCredit: async (token, service, params) => {
    try {
      const res = await apiFetch(`${greengoUrl}/interrop/transferCredit`, {
        method: "POST",
        body: JSON.stringify({ ...params, service }),
        headers: { "x-auth": token },
      });

      checkStatus(res);

      return await res.json();
    } catch (e) {
      console.error(e);
    }
  },
};
