import * as React from "react";
import { Text } from "react-native";
import env from "../environment/env";
import { usePairing } from "../hooks/pairing/usePairing";
import { useBadgeNumber } from "../hooks/useBadgeNumber";
import { LcgButton } from "./lcgComponents/LcgButton";
import { LcgContainer } from "./lcgComponents/LcgContainer";
import { LcgFooter } from "./lcgComponents/LcgFooter";
import { LcgHeader } from "./lcgComponents/LcgHeader";
import { LcgInput } from "./lcgComponents/LcgInput";
import { LcgLabel } from "./lcgComponents/LcgLabel";
import { LcgPopup } from "./lcgComponents/LcgPopup";

export const InnovorderHome: React.FunctionComponent<{}> = () => {
  const [
    badgeNumber,
    setBadgeNumber,
    getPairWithBadgePayload,
  ] = useBadgeNumber();

  const [onConnect, popupDisplayed, popupContent, pending] = usePairing(
    "innovorder",
    "Pensez à vérifier votre connexion ainsi que la bonne saisie de votre numéro de badge",
    "Nous vous avons bien affilié à votre e-wallet mais le transfert de votre cagnotte n'a pas pu aboutir",
    getPairWithBadgePayload
  );

  return (
    <>
      <LcgContainer>
        <LcgHeader>AFFILIER MA CAGNOTTE A MON COMPTE E-WALLET</LcgHeader>
        <LcgInput onValueChange={setBadgeNumber}>N° de badge</LcgInput>
        <LcgLabel header={true}>Comment trouver mon numéro de badge ?</LcgLabel>
        <LcgLabel>
          1. Connectez vous à votre E-Wallet sur{" "}
          <Text
            accessibilityRole="link"
            style={{ textDecorationLine: "underline", paddingRight: 10 }}
            onPress={() => {
              window.open(
                `https://ewallet.innovorder.fr/${env.INNOVORDER_BRAND_ID}/home`,
                "_blank"
              );
            }}
          >
            https://ewallet.innovorder.fr/{env.INNOVORDER_BRAND_ID}/home
          </Text>
        </LcgLabel>
        <LcgLabel>
          2. Faites glisser le solde vers la gauche pour afficher le code-barre.
        </LcgLabel>
        <LcgLabel>
          3. Copiez le numéro visible sous le code-barre et collez-le dans le
          champ ci-dessus.
        </LcgLabel>
      </LcgContainer>
      <LcgFooter>
        <LcgButton
          disabled={badgeNumber === "" || badgeNumber === null || pending}
          onClick={onConnect}
        >
          S'affilier
        </LcgButton>
      </LcgFooter>
      {popupDisplayed && popupContent && (
        <LcgPopup {...popupContent}></LcgPopup>
      )}
    </>
  );
};
