import * as React from "react";
import { JESPairRequest } from "../models/affiliation";
import { useQuery } from "./useQuery";

export const useJesInfo = () => {
  const [firstname, setFirstname] = React.useState<string>("");
  const [lastname, setLastname] = React.useState<string>("");
  const [solde, setSolde] = React.useState<string>("");

  const query = useQuery();

  const getJesPairPayload = React.useCallback<() => JESPairRequest>(() => {
    return {
      firstname,
      lastname,
      solde: parseFloat(solde.replace(",", ".")),
      cagnotteId: parseFloat(query?.get("cagnotteId")!),
      userId: parseFloat(query?.get("userId")!),
    };
  }, [firstname, lastname, solde, query]);

  return [
    firstname,
    setFirstname,
    lastname,
    setLastname,
    solde,
    setSolde,
    getJesPairPayload,
  ] as const;
};
