import * as React from "react";
import { Text } from "react-native";
import { usePairing } from "../hooks/pairing/usePairing";
import { useJesInfo } from "../hooks/useJesInfo";
import { LcgButton } from "./lcgComponents/LcgButton";
import { LcgContainer } from "./lcgComponents/LcgContainer";
import { LcgFooter } from "./lcgComponents/LcgFooter";
import { LcgHeader } from "./lcgComponents/LcgHeader";
import { LcgInput } from "./lcgComponents/LcgInput";
import { LcgLabel } from "./lcgComponents/LcgLabel";
import { LcgPopup } from "./lcgComponents/LcgPopup";

export const JesHome: React.FunctionComponent<{}> = () => {
  const [
    firstname,
    setFirstname,
    lastname,
    setLastname,
    solde,
    setSolde,
    getJesPairPayload,
  ] = useJesInfo();

  const [onConnect, popupDisplayed, popupContent, pending] = usePairing(
    "JES",
    "Pensez à vérifier votre connexion ainsi que la saisie de vos informations et le montant du solde de votre badge entreprise (inscrit sur votre reçu ou à demander en caisse)",
    "Nous avons affilié votre badge entreprise mais le transfert de votre cagnotte n'a pas pu aboutir",
    getJesPairPayload
  );

  return (
    <>
      <LcgContainer>
        <LcgHeader>
          {"AFFILIER MA CAGNOTTE \n A MON BADGE ENTREPRISE"}
        </LcgHeader>
        <LcgInput onValueChange={setFirstname}>Prénom</LcgInput>
        <LcgInput onValueChange={setLastname}>Nom</LcgInput>
        <LcgInput onValueChange={setSolde}>Dernier solde (en €)</LcgInput>
        <LcgLabel>
          <Text style={{ fontStyle: "italic" }}>
            Inscrit sur votre reçu ou à demander en caisse
          </Text>
        </LcgLabel>
      </LcgContainer>
      <LcgFooter>
        <LcgButton
          disabled={
            firstname === "" || lastname === "" || solde === "" || pending
          }
          onClick={onConnect}
        >
          S'affilier
        </LcgButton>
      </LcgFooter>
      {popupDisplayed && popupContent && (
        <LcgPopup {...popupContent}></LcgPopup>
      )}
    </>
  );
};
