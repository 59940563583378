import * as React from "react";
import { StyleSheet, View } from "react-native";
import { LcgBlue } from "./variables";

export const LcgFooter: React.FunctionComponent = ({ children }) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: LcgBlue,
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    paddingVertical: 20,
    alignSelf: "flex-end",
  },
});
