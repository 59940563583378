import * as React from "react";
import { StyleSheet, Text, View } from "react-native";

export const LcgHeader: React.FunctionComponent = ({ children }) => {
  return (
    <View style={styles.container}>
      <Text style={styles.text}>{children}</Text>
    </View>
  );
};

const styles = StyleSheet.create({
  container: {
    height: 100,
    paddingVertical: 20,
  },
  text: { textAlign: "center" },
});
