export class TransferError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class UnhandledPairError extends Error {
  constructor(message: string) {
    super(message);
  }
}

export class PairError extends Error {
  constructor(message: string) {
    super(message);
  }
}
