import * as React from "react";
import { BadgePairRequest } from "../models/affiliation";
import { useQuery } from "./useQuery";

export const useBadgeNumber = () => {
  const [badgeNumber, setBadgeNumber] = React.useState<string>("");

  const query = useQuery();

  const getPairWithBadgePayload = React.useCallback<
    () => BadgePairRequest
  >(() => {
    return {
      badgeNumber,
      cagnotteId: parseFloat(query?.get("cagnotteId")!),
      userId: parseFloat(query?.get("userId")!),
    };
  }, [badgeNumber, query]);

  return [badgeNumber, setBadgeNumber, getPairWithBadgePayload] as const;
};
