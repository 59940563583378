import * as React from "react";
import { Image, StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { noop } from "../../utils/noop";
import { LcgButton } from "./LcgButton";
import { LcgBlue } from "./variables";

interface LcgPopupProps {
  title: string;
  intro?: string;
  details: string;
  button?: string;
  onClose?: () => void;
  onValidate?: () => void;
}

export const LcgPopup: React.FunctionComponent<LcgPopupProps> = ({
  title,
  intro,
  details,
  button,
  onClose,
  onValidate,
}) => {
  return (
    <TouchableOpacity style={styles.container} onPress={onClose ?? noop}>
      <TouchableOpacity style={styles.popupContainer} activeOpacity={1}>
        {onClose && (
          <TouchableOpacity
            style={styles.closeImageContainer}
            onPress={onClose}
          >
            <Image
              style={styles.closeImage}
              source={require("../../../assets/lcgComponents/Close.png")}
            ></Image>
          </TouchableOpacity>
        )}

        <Text style={styles.title}>{title}</Text>
        {<Text style={styles.intro}>{intro}</Text>}
        <Text style={styles.details}>{details}</Text>
        {onValidate && (
          <View style={styles.buttonContainer}>
            {" "}
            <LcgButton onClick={onValidate}>{button}</LcgButton>
          </View>
        )}
      </TouchableOpacity>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  container: {
    position: "absolute",
    width: "100%",
    height: "100%",
    backgroundColor: "rgba(212, 255, 235, 0.7)",
    alignItems: "center",
    justifyContent: "center",
  },
  popupContainer: {
    padding: "0.5em",
    width: "80vw",
    maxWidth: 300,
    minHeight: 300,
    borderRadius: 10,
    borderWidth: 5,
    borderColor: "#FFFFFF",
    backgroundColor: "#D4FFEB",
    alignItems: "center",
  },
  closeImageContainer: {
    height: 45,
    width: 45,
    top: 10,
    alignSelf: "flex-end",
  },
  closeImage: {
    height: 30,
    width: 30,
  },
  title: {
    fontSize: 20,
    color: LcgBlue,
    fontWeight: "bold",
    textAlign: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  intro: {
    paddingTop: 10,
    fontSize: 14,
    color: LcgBlue,
    fontWeight: "bold",
    textAlign: "center",
  },
  details: {
    fontSize: 14,
    color: LcgBlue,
    fontWeight: "normal",
    textAlign: "center",
  },
  buttonContainer: {
    marginTop: 40,
  },
});
