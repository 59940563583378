import * as React from "react";
import { StyleSheet, View } from "react-native";
import { LcgGreen } from "./variables";

interface LcgContainerProps {
  children: React.ReactNode;
}

export const LcgContainer: React.FunctionComponent<LcgContainerProps> = ({
  children,
}) => {
  return <View style={styles.container}>{children}</View>;
};

const styles = StyleSheet.create({
  container: {
    backgroundColor: LcgGreen,
    flex: 1,
    justifyContent: "center",
    alignItems: "center",
    paddingHorizontal: 60,
  },
});
