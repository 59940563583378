import * as React from "react";
import { Linking } from "react-native";

export interface PopupInfo {
  title: string;
  intro?: string;
  details: string;
  button?: string;
  onValidate?: () => void;
  onClose?: () => void;
}

export const displayUnhandledErrorPopup = (
  setPopupContent: React.Dispatch<React.SetStateAction<PopupInfo | null>>,
  togglePopup: () => void
) => {
  setPopupContent({
    title: "Désolé",
    intro: "L'opération n’a pas pu aboutir...",
    details:
      "Veuillez réessayer et nous contacter via le menu de votre app' La Consigne GreenGo si le problème persiste",
    button: "Ok",
    onValidate: () => {
      togglePopup();
    },
  });
  togglePopup();
};

export const displayTransferErrorPopup = (
  setPopupContent: React.Dispatch<React.SetStateAction<PopupInfo | null>>,
  togglePopup: () => void,
  transferErrorMessage: string,
  query: URLSearchParams
) => {
  setPopupContent({
    title: "Désolé",
    intro: transferErrorMessage,
    details: "Veuillez nous contacter pour résoudre ce problème",
    button: "Nous contacter",
    onValidate: () => {
      togglePopup();
      let url = `mailto:support@laconsignegreengo.com?subject=Erreur transfert - ${query.get(
        "userId"
      )}`;
      Linking.openURL(url);
    },
  });
  togglePopup();
};

export const displayPairErrorPopup = (
  setPopupContent: React.Dispatch<React.SetStateAction<PopupInfo | null>>,
  togglePopup: () => void,
  pairErrorMessage: string
) => {
  setPopupContent({
    title: "Désolé !",
    intro: "L'affiliation n'a pas pu aboutir",
    details: pairErrorMessage,
    onClose: () => {
      togglePopup();
    },
  });
  togglePopup();
};
