import * as React from "react";
import { StyleSheet, Text } from "react-native";
import { LcgBlue } from "./variables";

interface LcgLabelProps {
  children?: React.ReactNode | undefined;
  alignment?: "center" | "left";
  header?: boolean;
}

export const LcgLabel: React.FunctionComponent<LcgLabelProps> = ({
  children,
  alignment = "center",
  header = false,
}) => {
  return (
    <Text
      style={[header ? styles.header : styles.text, { textAlign: alignment }]}
    >
      {children}
    </Text>
  );
};

const styles = StyleSheet.create({
  header: {
    fontSize: 20,
    color: LcgBlue,
    fontWeight: "bold",
    marginTop: 15,
    marginBottom: 15,
  },
  text: {
    fontSize: 14,
    color: LcgBlue,
    fontWeight: "normal",
    marginVertical: 10,
  },
});
