import * as React from "react";

export const useToggle = () => {
  const [toggleOn, setToggleOn] = React.useState(false);

  const toggle = React.useCallback(() => {
    setToggleOn((value) => !value);
  }, [setToggleOn]);

  return [toggleOn, toggle] as const;
};
