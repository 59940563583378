import { StatusBar } from "expo-status-bar";
import React from "react";
import { InnovorderHome } from "./src/components/InnovorderHome";
import { JesHome } from "./src/components/JesHome";
import { XGilHome } from "./src/components/XGilHome";
import { TokenProvider } from "./src/context/tokenContext";
import { useFragment } from "./src/hooks/useFragment";
import { useQuery } from "./src/hooks/useQuery";

export default function App() {
  const fragment = useFragment();
  const service = useQuery()?.get("service");

  return (
    <TokenProvider value={fragment?.get("segredo") ?? null}>
      {service === "xgil" ? (
        <XGilHome />
      ) : service === "innovorder" ? (
        <InnovorderHome />
      ) : service === "JES" ? (
        <JesHome />
      ) : null}
      <StatusBar style="auto" />
    </TokenProvider>
  );
}
