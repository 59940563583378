import * as React from "react";
import { tokenContext } from "../../context/tokenContext";
import { PairRequest } from "../../models/affiliation";
import { useQuery } from "../useQuery";
import { useToggle } from "../useToggle";
import { pairUser, transferCredit } from "./affiliation";
import { PairError, TransferError } from "./error";
import {
  displayPairErrorPopup,
  displayTransferErrorPopup,
  displayUnhandledErrorPopup,
  PopupInfo,
} from "./popup";

export const usePairing = (
  service: string,
  pairErrorMessage: string,
  transferErrorMessage: string,
  getPairPayload: () => PairRequest
) => {
  const query = useQuery();
  const mobileAppToken = React.useContext(tokenContext);

  const [popupDisplayed, togglePopup] = useToggle();
  const [popupContent, setPopupContent] = React.useState<PopupInfo | null>(
    null
  );

  const [pending, setPending] = React.useState<boolean>(false);

  const onConnect = React.useCallback(async () => {
    if (query !== null) {
      const pairPayload = getPairPayload();
      const cagnotteId = parseInt(query.get("cagnotteId")!);
      const userId = parseInt(query.get("userId")!);

      try {
        setPending(true);
        await pairUser(mobileAppToken, service, pairPayload);
        await transferCredit(
          mobileAppToken,
          service,
          { cagnotteId, userId },
          setPopupContent,
          togglePopup,
          query.get("app")!
        );
        setPending(false);
      } catch (e) {
        setPending(false);
        if (e instanceof PairError) {
          displayPairErrorPopup(setPopupContent, togglePopup, pairErrorMessage);
        } else if (e instanceof TransferError) {
          displayTransferErrorPopup(
            setPopupContent,
            togglePopup,
            transferErrorMessage,
            query
          );
        } else {
          displayUnhandledErrorPopup(setPopupContent, togglePopup);
        }
      }
    }
  }, [
    query,
    getPairPayload,
    service,
    pairErrorMessage,
    transferErrorMessage,
    togglePopup,
    setPopupContent,
  ]);

  return [onConnect, popupDisplayed, popupContent, pending] as const;
};
