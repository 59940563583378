import * as React from "react";

export const useQuery = () => {
  const [
    searchParams,
    setSearchParams,
  ] = React.useState<URLSearchParams | null>(null);

  React.useEffect(() => {
    setSearchParams(new URLSearchParams(window.location.search));
  }, [setSearchParams]);

  return searchParams;
};
