import * as React from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { LcgBlue } from "./variables";

interface LcgInputProps {
  children?: string;
  content?: string;
  onValueChange?: (input: string) => void;
  onFocus?: () => void;
}

export const LcgInput: React.FunctionComponent<LcgInputProps> = ({
  children,
  content,
  onValueChange,
  onFocus,
}) => {
  const [value, setValue] = React.useState<string>(content ?? "");
  const doValueChanged = React.useCallback(
    (value: string) => {
      setValue(value);
      onValueChange?.(value);
    },
    [setValue, onValueChange]
  );

  return (
    <View style={styles.textInputView}>
      <TextInput
        multiline={false}
        style={styles.textInput}
        placeholder={children}
        placeholderTextColor="#6796FF"
        onChangeText={(input) => doValueChanged(input)}
        value={value}
        onFocus={onFocus}
      ></TextInput>
    </View>
  );
};

const styles = StyleSheet.create({
  textInputView: {
    backgroundColor: "#fff",
    width: "100%",
    height: 62,
    borderColor: "#8BFEF6",
    borderWidth: 4,
    borderRadius: 31,
    justifyContent: "center",
    alignItems: "center",
    marginTop: 15,
    marginBottom: 15,
  },
  textInput: {
    fontSize: 16,
    color: LcgBlue,
    fontWeight: "bold",
    textAlign: "center",
    outlineColor: "transparent",
    outlineWidth: 0,
    outlineStyle: "none",
    boxShadow: "none",
    appearance: "none",
  },
});
