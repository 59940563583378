type Fetch = (url: RequestInfo, init?: RequestInit) => Promise<Response>;

export const apiFetch: Fetch = (input, init) => {
  const requestInit: RequestInit = {
    ...init,
    headers: {
      ...init?.headers,
      "Content-Type": "application/json",
    },
  };
  return fetch(input, requestInit);
};

export function checkStatus(res: Response) {
  if (res.status < 200 || res.status >= 300) {
    throw new Error(
      JSON.stringify({ response: res.statusText, statusCode: res.status })
    );
  }
}
