import * as React from "react";

export const useFragment = () => {
  const [
    searchParams,
    setSearchParams,
  ] = React.useState<URLSearchParams | null>(null);

  React.useEffect(() => {
    if (
      window.location.hash !== null &&
      window.location.hash !== undefined &&
      window.location.hash !== ""
    ) {
      setSearchParams(
        new URLSearchParams(window.location.hash.replace("#", ""))
      );
      window.location.hash = ""; //we remove this value to avoid XXS attacks if the user go to an external link
    }
  }, [setSearchParams]);

  return searchParams;
};
