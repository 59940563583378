import * as React from "react";
import { StyleSheet, Text, TouchableOpacity, View } from "react-native";
import { LcgBlue, LcgGreen } from "./variables";

interface LcgButtonProps {
  onClick: () => void;
  disabled?: boolean;
  children?: string;
}

export const LcgButton: React.FunctionComponent<LcgButtonProps> = ({
  onClick,
  children,
  disabled = false,
}) => {
  return (
    <TouchableOpacity
      style={disabled ? styles.faded : null}
      onPress={onClick}
      disabled={disabled}
    >
      <View style={styles.button}>
        <Text style={styles.textButton}>{children}</Text>
      </View>
    </TouchableOpacity>
  );
};

const styles = StyleSheet.create({
  faded: {
    opacity: 0.5,
    backgroundColor: LcgGreen,
    borderRadius: 31,
  },
  button: {
    backgroundColor: "#fff",
    padding: 10,
    borderColor: "#FF9CFF",
    borderWidth: 4,
    borderRadius: 31,
    justifyContent: "center",
    alignItems: "center",
    paddingLeft: 10,
    paddingRight: 10,
    minWidth: 150,
  },
  textButton: {
    fontSize: 16,
    color: LcgBlue,
    fontWeight: "bold",
    textAlign: "center",
  },
});
