import active from "./active.env";

interface Env {
  GREENGO_API: string;
  INNOVORDER_BRAND_ID: number;
}

const envs: Record<string, Env> = {
  local: {
    GREENGO_API: "http://localhost:3000",
    INNOVORDER_BRAND_ID: -1000, // we use unvalid brand id to avoid creating account on other restaurants
  },
  dev: {
    GREENGO_API: "https://api-dev.mygreengo.fr",
    INNOVORDER_BRAND_ID: -1000,
  },
  staging: {
    GREENGO_API: "https://api-staging.mygreengo.fr",
    INNOVORDER_BRAND_ID: -1000,
  },
  prod: {
    GREENGO_API: "https://api.mygreengo.fr",
    INNOVORDER_BRAND_ID: 1074,
  },
};

export default envs[active];
